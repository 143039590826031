import React from "react"
import { graphql, Link } from "gatsby"
import Helmet from "react-helmet"
import classnames from "classnames"
import { MDXRenderer } from "gatsby-plugin-mdx"

import Layout from "../components/layout"
import SEO from "../components/seo"

import blogPostDate from "../helpers/formatDate"
import getCategoriesById from "../helpers/getCategoriesById"

import styles from "../scss/post.module.scss"

export default function Post({ data, children, location }) {
  const { frontmatter, body } = data.mdx
  const { date, title, categories } = frontmatter
  const sluggedCategories = getCategoriesById(categories)
  const postId = data.mdx.id
  const postIndex = data?.posts?.edges?.findIndex(
    post => post.node.id === postId
  )

  const renderSocialMediaBlock = () => {
    const { title } = frontmatter
    const shareTitle = encodeURIComponent(title)
    const shareUrl = encodeURIComponent(location.href)
    const socialNetworks = [
      {
        name: "Facebook",
        url: `https://www.facebook.com/sharer/sharer.php?t=${shareTitle}&u=${shareUrl}`,
        icon: (
          <svg
            className={classnames(
              styles.post__shareItemIcon,
              styles.post__shareItemIconFacebook
            )}
            xmlns="http://www.w3.org/2000/svg"
            width="384"
            height="448"
            viewBox="0 0 384 448"
          >
            <path d="M362.75 32C374.5 32 384 41.5 384 53.25v341.5c0 11.75-9.5 21.25-21.25 21.25H265V267.25h49.75l7.5-58H265v-37c0-16.75 4.5-28 28.75-28l30.5-.25V92.25C319 91.5 300.75 90 279.75 90 235.5 90 205 117 205 166.5v42.75h-50v58h50V416H21.25C9.5 416 0 406.5 0 394.75V53.25C0 41.5 9.5 32 21.25 32h341.5z" />
          </svg>
        ),
      },
      {
        name: "Twitter",
        url: `https://twitter.com/share?text=${shareTitle}&url=${shareUrl}`,
        icon: (
          <svg
            className={classnames(
              styles.post__shareItemIcon,
              styles.post__shareItemIconTwitter
            )}
            xmlns="http://www.w3.org/2000/svg"
            width="384"
            height="448"
            viewBox="0 0 384 448"
          >
            <path d="M320 152.5c-9.5 4.25-19.5 7-30.25 8.5 11-6.5 19.25-17 23.25-29.25-10.25 6-21.5 10.5-33.5 12.75-9.5-10.25-23.25-16.5-38.25-16.5-29 0-52.5 23.5-52.5 52.5 0 4 .25 8.25 1.25 12-43.75-2.25-82.5-23-108.5-55-4.5 7.75-7.25 17-7.25 26.5 0 18.25 8.5 34.25 22.75 43.75-8.75-.25-17-2.75-25-6.5v.5c0 25.5 19.25 46.75 43.25 51.5-4.5 1.25-8 2-12.75 2-3.25 0-6.5-.5-9.75-1 6.75 20.75 26 36 49 36.5-18 14-40.5 22.5-65.25 22.5-4.25 0-8.5-.25-12.5-.75 23.25 14.75 50.75 23.5 80.5 23.5C241 336 294 256 294 186.5c0-2.25 0-4.5-.25-6.75C304 172.5 313 163.25 320 152.5zm64-48.5v240c0 39.75-32.25 72-72 72H72c-39.75 0-72-32.25-72-72V104c0-39.75 32.25-72 72-72h240c39.75 0 72 32.25 72 72z" />
          </svg>
        ),
      },
      {
        name: "Pocket",
        url: `https://getpocket.com/edit?url=${shareUrl}`,
        icon: (
          <svg
            className={classnames(
              styles.post__shareItemIcon,
              styles.post__shareItemIconPocket
            )}
            xmlns="http://www.w3.org/2000/svg"
            width="430"
            height="448"
            viewBox="0 0 430 448"
          >
            <path d="M391.25 32C413 32 430 49.5 430 71v129.75C430 320.5 334.25 416 215.25 416 95.75 416 0 320.5 0 200.75V71c0-21.25 17.75-39 39-39h352.25zm-176 266c7.5 0 15-3 20.5-8.25l101-97c5.75-5.5 9.25-13.25 9.25-21.25 0-16.25-13.25-29.5-29.5-29.5-7.75 0-15 3-20.5 8.25l-80.75 77.5-80.75-77.5C129 145 121.75 142 114.25 142c-16.25 0-29.5 13.25-29.5 29.5 0 8 3.25 15.75 9 21.25l101.25 97c5.25 5.25 12.75 8.25 20.25 8.25z" />
          </svg>
        ),
      },
    ]

    return (
      <div className={styles.post__share}>
        <p>
          <strong>Diesen Beitrag teilen:</strong>
        </p>
        <div className={styles.post__shareList}>
          {socialNetworks.map(network => {
            return (
              <div key={network.name} className={styles.post__shareItem}>
                <a
                  href={network.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.post__shareItemLink}
                >
                  {network.icon}
                  {network.name}
                </a>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  const renderNextPostLink = () => {
    try {
      if (data.posts.edges[postIndex + 1]) {
        const nextPost = data.posts.edges[postIndex + 1]
        const { node } = nextPost
        const { slug } = node.fields
        const { title } = node.frontmatter

        return <Link to={slug}>{title}&nbsp;»</Link>
      }
    } catch (e) {
      return null
    }
  }

  const renderPrevPostLink = () => {
    try {
      if (data.posts.edges[postIndex - 1]) {
        const prevPost = data.posts.edges[postIndex - 1]
        const { node } = prevPost
        const { slug } = node.fields
        const { title } = node.frontmatter

        return <Link to={slug}>«&nbsp;{title}</Link>
      }
    } catch (e) {
      return null
    }
  }

  return (
    <Layout>
      <SEO title={title} />
      <Helmet>
        <link
          rel="webmention"
          href="https://webmention.io/niklaskoehler.de/webmention"
        />
        <link
          rel="pingback"
          href="https://webmention.io/niklaskoehler.de/xmlrpc"
        />
      </Helmet>

      <div className={styles.post}>
        <h1 className={styles.post__headline}>{title}</h1>
        <div className={styles.post__meta}>
          <div
            className={styles.post__metaItem}
            dateTime={new Date(date).toISOString()}
          >
            Veröffentlicht am: {blogPostDate(date)}
          </div>

          {categories && categories.length && (
            <div className={styles.post__metaItem}>
              in {categories.length === 1 ? "der Kategorie" : "den Kategorien"}{" "}
              {sluggedCategories.map((link, index) => (
                <React.Fragment key={link.name}>
                  <Link to={link.slug}>{link.name}</Link>
                  {sluggedCategories.length - 1 !== index ? ", " : ""}
                </React.Fragment>
              ))}
            </div>
          )}
        </div>
        <div className={styles.post__content}>
          <MDXRenderer>{body}</MDXRenderer>
        </div>

        {renderSocialMediaBlock()}

        <div className="pt-4 mt-4 border-top border-gray">
          <div className="row">
            <div className="col-md-6 text-center text-md-left">
              {renderPrevPostLink()}
            </div>
            <div className="ml-auto col-md-6 mt-3 mt-md-0 text-center text-md-right">
              {renderNextPostLink()}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      id
      body
      fields {
        slug
      }
      frontmatter {
        date
        title
        categories
      }
    }
    posts: allMdx(
      filter: { fileAbsolutePath: { regex: "/pages/bildungsblog/.*.mdx/" } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
